import React from 'react';
import { HashRouter as Router, Link } from "react-router-dom";

function Footer(props) {
  if(props.isMobile) {
    return (
      <footer id="footer" className="phone-footer-wrapper">
        <div className="phone-footer-detail">
          {/* <div className="followBox">
            <div className="detailTitle">Síganos</div>
            <div className="oneAccount">
              <svg className="fIcon">
                <use xlinkHref="#iconfacebook"/>
              </svg>
              Facebook
            </div>
            <div className="oneAccount">
              <svg className="tIcon">
                <use xlinkHref="#icontwitter"/>
              </svg>
              Twitter
            </div>
            <div className="oneAccount">
              <svg className="yIcon">
                <use xlinkHref="#iconyoutube"/>
              </svg>
              Youtube
            </div>
          </div> */}
          <div className="regulation">
            <div className="detailTitle">Regulaciones</div>
            <Link to="/creditAgreement" className="oneRegu oneReguLarge" target="_blank" rel="noopener noreferrer">《HeyCredito Contrato》</Link>
            <Link to="/privacyAgreement" className="oneRegu" target="_blank" rel="noopener noreferrer">《Aviso de Privacidad》</Link>
            {/* <Link to="/serviceAgreement" className="oneRegu" target="_blank" rel="noopener noreferrer">《Acuerdo de Servicio》</Link> */}
          </div>
        </div>
        <div className="phone-footer-bg">
          <div className="emailBox">
            {/* <div className="emailTitle">Cetustech S.A. de C.V.</div> */}
            <div className="emailDetail">
              <svg className="eIcon">
                <use xlinkHref="#iconmail" />
              </svg>
              service@heycredito.com
            </div>
          </div>
        </div>
      </footer>
    )
  }
  return (
    <footer id="footer" className="footer-wrapper">
      <div className="footer-bar">
        <div className="footer-content">
          <div className="one-center">
            {/* <h2>Cetustech S.A. de C.V.</h2> */}
            <div>
              <svg>
                <use xlinkHref="#iconmail" />
              </svg>
              <p>service@heycredito.com</p>
            </div>
          </div>
         
          <div className="one-center">
            <h2>Regulaciones</h2>
            <Router>
              <Link to="/creditAgreement" target="_blank" rel="noopener noreferrer" >
              《HeyCredito Contrato》
              </Link>
              <Link to="/privacyAgreement" target="_blank" rel="noopener noreferrer" >
              《Aviso de Privacidad》
              </Link>
             
            </Router>
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        Copyright © 2019-2021  | HeyCredito | All Rights Reserved
      </div>
    </footer>
  );
}


export default Footer;
