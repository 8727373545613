import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'rc-scroll-anim';
// import websiteBanner from '../assets/imgs/website-bg1.png';
import mobileBg from '../assets/imgs/mobileBg.png';
// import downloadImg from '../assets/imgs/website-download.png';
// import loanImg from '../assets/imgs/website-loan.png';

class Banner extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    navToShadow: PropTypes.func,
    
  }
  static defaultProps = {
    className: 'banner',
  }
  constructor(props) {
    super(props)
    this.state = {
      appUrl: '',
    }
  }
  componentDidMount(){
    console.log(window.location.origin)
    // fetch('http://www.iprestamos.mx/api/download/page/config?hash=1',{
    fetch( window.location.origin + '/api/download/page/config?hash=1',{
      method:'GET',
      headers:{'Content-Type':'application/json;charset=UTF-8'},
      mode:'no-cors',
      cache:'default'
    })
    .then(res =>res.json())
    .then((res) => {
      if(res.ret === 0) {
        const { data={} } = res;
        this.setState({
          appUrl: data.url,
        })
      }
    }) 
  }
  render() {
    const { className, navToShadow, isMobile } = this.props;
    const { appUrl } = this.state;

    // const appUrl = 'http://s.iprestamos.mx/6yZwcj';

    console.log(className);
    if(isMobile) {
      return (
        <Element id="home" component="section" className={`${className}-wrapper`} onChange={navToShadow}>
          <div className="phone-banner-wrapper">
            <p className="banner-title">{'¡Le ayudamos a obtener un préstamo más fácilmente!'}</p>
            <p className="banner-desc">
              {'En 4 pasos sencillos,'}
              <br />
              {'obtenga su dinero en un plazo de 2 a 24 horas.'}
            </p>
            <p className="banner-more">{'Registrarse • enviar información • solicitar • retirarse'}</p>
            <a target="_blank" rel="noopener noreferrer" href={appUrl} key="a" className="phone-download">
              <svg>
                <use xlinkHref="#iconxiazai" />
              </svg>
              {'Descargar HeyCredito'}
            </a>
            <img src={mobileBg} className={`${className}-mobileBg`} alt="mobileBg"/>
        </div>
      </Element>
      )
    }
    
    return (
      <Element id="home" component="section" className={`${className}-wrapper page`} onChange={navToShadow}>
        <div className={className}>
          {/* <img src={websiteBanner} className={`${className}-bg-img`} alt="banner" /> */}
          <div className={`${className}-bg-word-wrapper`}>
            <div className={`${className}-word-main`}>
              <div className={`${className}-word-left`}>
                <p className={`${className}-word-left-title`}>{'¡Le ayudamos a obtener un préstamo más fácilmente!'}</p>
                <p className={`${className}-word-left-subtitle`}>{'En 4 pasos sencillos, obtenga su dinero en un plazo de 2 a 24 horas.'}</p>
                <p className={`${className}-word-left-desc`}>{'Registrarse • enviar información • solicitar • retirarse'}</p>
                <a target="_blank" rel="noopener noreferrer" href={appUrl} key="a" className={`${className}-word-download`}>
                  <svg>
                    <use xlinkHref="#iconxiazai" />
                  </svg>
                  {'Descargar HeyCredito'}
                </a>
              </div>
            </div>
          </div>
        </div>
      </Element>
    );
  }
}

export default Banner;
