import featureIcon1 from '../assets/imgs/featureIcon1.png';
import featureIcon2 from '../assets/imgs/featureIcon2.png';
import featureIcon3 from '../assets/imgs/featureIcon3.png';
import featureIcon4 from '../assets/imgs/featureIcon4.png';

import faq1Img from '../assets/imgs/faqIcon1.png';
import faq2Img from '../assets/imgs/faqIcon2.png';
import faq3Img from '../assets/imgs/faqIcon3.png';


export const page1 = [
  {
    img: featureIcon1,
    title: 'MÁS RÁPIDO',
    description: '1 DNI, 1 número de móvil,<br/>el préstamo emitido en<br/>2 a 24 horas',
  },
  {
    img: featureIcon2,
    title: 'MÁS SEGURO',
    description: 'Información personal cifrada<br/> para garantizar la seguridad,<br/>sólo utilizada para a revisión<br/>y certificación',
  },
  {
    img: featureIcon3,
    title: 'MÁS FÁCIL',
    description: 'Múltiples fuentes<br/>(CLABE,OXXO),<br/>tan fácil como<br/>comprar una cola',
  },
  {
    img: featureIcon4,
    title: 'MÁS COMO UN AMIGO',
    description: 'Un equipo fuerte siempre<br/>está listo para servirle<br/>cuando encuentre dificultades',
  },
];

export const page3 = [
  {
    img: faq1Img,
    title: '¿Qué cualificaciones se requieren？',
    descriptions: [
      'Un número de teléfono en uso',
      'Identificación gubernamental válida',
      'Ingresos estables',
      'Edad entre 18 y 49 años'
    ],
  },
  {
    img: faq2Img,
    title: '¿Cómo solicitar un préstamo?',
    descriptions: [
      'Instale HeyCredito y regístrese con su propio número de teléfono.',
      'Rellene el formulario de solicitud de préstamo en pesos.',
      'Espere la aprobación del préstamo en efectivo.',
      'Reclame su préstamo personal.'
    ],
  },
  {
    img: faq3Img,
    title: '¿Cómo pagar el préstamo en efectivo?',
    descriptions: [
      'Banco - CLABE: Deposita el dinero en la cuenta corporativa CLABE de HeyCredito indicada en la aplicación.',
      'OXXO: Vaya al OXXO más cercano, rellene la información requerida en el formulario de pago de facturas o muestre el código de barras al cajero, reembolse sus préstamos personales y reciba un recibo de confirmación de pago.'
    ]
  },
];
