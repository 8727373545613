import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Button, Popover } from 'antd';

import { enquireScreen } from 'enquire-js';
import websiteLogo from '../assets/imgs/okLogo.png';
import websiteLogo2 from '../assets/imgs/logo.png';


class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    activefaq: PropTypes.bool,
    activeProduct: PropTypes.bool,
    activeHome: PropTypes.bool,
    handleclick: PropTypes.func,
  }

  state = {
    menuVisible: false,
    menuMode: 'horizontal',
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ menuMode: b ? 'inline' : 'horizontal' });
    });
  }

  handleShowMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible});
  }

  handleMenuClick = (menu) => {
    this.props.handleclick(menu.key);
    this.setState({ menuVisible: false});
  }

  render() {
    const { activefaq, activeProduct, activeHome, handleclick, isMobile } = this.props;
    const { menuMode, menuVisible } = this.state;

    const menu = (
      <Menu mode="inline" id="nav" key="nav" onClick={(key) => this.handleMenuClick(key)}>
        <Menu.Item key="home" onClick={() => this.handleMenuClick('home')}>
          <span >Inicio</span>
        </Menu.Item>
        <Menu.Item key="product" onClick={() => this.handleMenuClick('product')}>
          <span >Producto</span>
        </Menu.Item>
        <Menu.Item key="FAQ" onClick={() => this.handleMenuClick('FAQ')}>
          <span >FAQ</span>
        </Menu.Item>
      </Menu>
    );

    return (
    <header className={this.props.className}>
      <div className={["headerWrapper", isMobile?"phoneHeader":""].join(' ')}>
        <div className="logoBox">
          {!isMobile && <img src={this.props.className ? websiteLogo2: websiteLogo} alt='logo'/>}
          {/* {!isMobile && <span>HeyCredito</span>} */}
        </div>
        {
          menuMode === 'horizontal' ? (
          <div>
            <div className={activefaq ? 'active-btn' : 'button'} onClick={() => handleclick('FAQ')}>
              <Button>FAQ</Button>
            </div>
            <div className={activeProduct ? 'active-btn' : 'button'} onClick={() => handleclick('product')}>
              <Button>Producto</Button>
            </div>
            <div className={activeHome ? 'active-btn' : 'button'} onClick={() => handleclick('home')}>
              <Button>Inicio</Button>
            </div>
          </div>) : (
            <Popover
              overlayClassName="popover-menu"
              placement="bottomRight"
              content={menu}
              trigger="click"
              visible={menuVisible}
              arrowPointAtCenter
              onVisibleChange={this.handleShowMenu}
            >
              <svg className="nav-phone-menu" onClick={this.handleShowMenu}>
                <use xlinkHref="#iconmenu1" />
              </svg>
            </Popover> 
          )
        }
      </div>
    </header>
    );
  }
}

export default Header;
