import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row } from 'antd';
import { page1 } from './data';


export default function Page1(props) {
  const renderCard = (data) => {
    const cardHtml = data.map((card, i) => (
      <div className="item-feature" key={i.toString()}>
        <img src={card.img} className="cardImg" alt='card'/>
        <p className="cardTitle">{card.title}</p>
        <p className="cardDetail" dangerouslySetInnerHTML={{__html: card.description}}></p>
      </div>
    ));
    return cardHtml;
  }

  const children = renderCard(page1);


  if(props.isMobile) {
    return (
      <section id="product" className="phone-feature-wrapper">
        <div className="phone-feature-title">{'Características'}</div>
        <div className="phone-feature-desc">
          {'Nuestro objetivo es facilitar los préstamos,'}
          <br/>
          {' ayudar a más usuarios necesitados y construir la relación.'}
        </div>
        <QueueAnim
          component={Row}
          type="bottom"
          className="phone-feature-list"
          delay={500}
        >
          {children}
        </QueueAnim>
      </section>
    )
  }

  return (
    <section id="product" className="page-wrapper page1">
      <div className="page-main-content">
        <p className="fsTitle">{'Características'}</p>
        <p className="fsDesc">
          {'Nuestro objetivo es facilitar los préstamos,'}
          <br/>
          {' ayudar a más usuarios necesitados y construir la relación.'}
        </p>
        <QueueAnim
          component={Row}
          type="bottom"
          className="page row text-left one-row-page"
          delay={500}
        >
          {children}
        </QueueAnim>
      </div>
    </section>);
}
