import React from 'react';
import DocumentTitle from 'react-document-title';
import { enquireScreen } from 'enquire-js';

import './CommonPage.less';

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});


class CreditAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
  }



  render() {
    return (
      <div className={'contentWrapper'}>
        <DocumentTitle title="HeyCredito Contrato" />
        <h1>Contrato de Usuario</h1>
        <p>Este Contrato es un contrato de licencia de usuario final entre usted (el “Usuario Final” o “usted”) y HeyCredito (en lo sucesivo, “HeyCredito,” “nosotros,” “nos” o “nuestro”), una sociedad anónima constituida, debidamente registrada y válidamente existente de conformidad con las leyes de México, con número de RFC **************, con domicilio en **************************************************, CDMX, sitio web: www.HeyCredito.com, que proporciona la App HeyCredito Mobile Android (“HeyCredito” o “App”).</p>
        <p></p>
        <p>Este Contrato (junto con nuestro Aviso de Privacidad y, en caso de celebrarse, el Contrato de Crédito y las páginas de la oferta del Crédito) establecen los Términos y Condiciones completos para el uso de la App.</p>
        <p></p>
        <p>Los Términos y Condiciones y sus modificaciones o variaciones surtirán efecto en su fecha de publicación; es su responsabilidad realizar el seguimiento de cualesquier modificaciones o variaciones.</p>
        <p></p>
        <p>Sección 1. DEFINICIONES E INTERPRETACIÓN</p>
        <p></p>
        <p>Los términos con mayúscula inicial utilizados en este Contrato tendrán los siguientes significados:</p>
        <p></p>
        <p>“Aviso de Privacidad” significa el aviso de privacidad en el que HeyCredito establece la base sobre la cual cualquier dato personal que recopilemos de usted, o que nos proporcione, será tratado por nosotros.</p>
        <p></p>
        <p>“Contrato” significa este Contrato de licencia de usuario final.</p>
        <p></p>
        <p>“Contrato de Crédito” significa el contrato de crédito celebrado por usted, como acreditado y HeyCredito, como acreedor, a ser celebrado a través de la App, mediante el cual usted podrá disponer de un Crédito, siempre y cuando usted tenga un historial crediticio satisfactorio.</p>
        <p></p>
        <p>“Credenciales” significa sus credenciales personales utilizadas para acceder a la App HeyCredito y operar su Cuenta.</p>
        <p></p>
        <p>“Crédito” significa el otorgamiento de un crédito por HeyCredito a usted a través de la App y de conformidad con el artículo 291 de la Ley General de Títulos y Operaciones de Crédito y demás disposiciones aplicables. El límite de crédito máximo variará según las características de suscripción y el historial de reembolso del deudor individual, entre otros factores.</p>
        <p></p>
        <p>“Cuenta” significa su cuenta con HeyCredito.</p>
        <p></p>
        <p>“CURP” significa Clave Única de Registro de Población.</p>
        <p></p>
        <p>“Destinatario” significa que la persona designada para recibir un Mensaje de Datos.</p>
        <p></p>
        <p>“Día Hábil” significa un día que no sea sábado, domingo o día festivo nacional o público en México.</p>
        <p></p>
        <p>“Equipo” incluye su teléfono móvil, Tarjeta SIM y/u otro equipo que, cuando se usan juntos, le permite acceder a la red de telecomunicaciones.</p>
        <p></p>
        <p>“Firma Electrónica” significa todos aquellos datos proporcionados que se registran electrónicamente en un Mensaje de Datos, o unidos o asociados de manera lógica a dicho Mensaje de Datos por cualquier tecnología que se utiliza para identificar el firmante e indicar que el firmante aprueba la información contenida en el Mensaje de Datos, produciendo el mismo efecto legal que una firma manuscrita, siendo admisible como evidencia en juicio.</p>
        <p></p>
        <p>“Fuerza Mayor” significa eventos, circunstancias o causas más allá de un control razonable haciendo que el desempeño de las obligaciones sea desaconsejable, comercialmente imposible, ilegal o imposible, incluyendo pero no limitado al caso fortuito, guerra, huelgas o conflictos laborales, embargos u órdenes gubernamentales.</p>
        <p></p>
        <p>“Gravamen” incluye cualquier hipoteca o carga (ya sea legal o equitativo), gravamen, opción, derecho de garantía, obligación contractual restrictiva, prenda, cesión, la retención de título o reserva de dominio, acuerdo de fideicomiso u otra restricción de cualquier tipo u otro gravamen que garantice o cualquier derecho que confiere una prioridad de pago con respecto a, cualquier obligación de cualquier persona.</p>
        <p></p>
        <p>“Información Personal” significa su número de teléfono, nombre, fecha de nacimiento, número de identificación, número de pasaporte, CURP y toda otra información que nos permita identificarlo y cumplir con los requisitos reglamentarios “Conozca a Su Cliente”.</p>
        <p></p>
        <p>“Información Relevante” significa información incluyendo, pero no limitada a, los datos relativos a su teléfono (incluyendo, sin limitación, el historial de su teléfono) de su Equipo, desde cualquier SMS enviado a usted por cualquier proveedor de servicios financieros y otra información que podríamos solicitar con el propósito de la prestación de los Servicios.</p>
        <p></p>
        <p>“Mensaje de Datos” significa toda la información creada, entregada, recibida o grabada por medios electrónicos, ópticos o de cualquier otro tipo.</p>
        <p></p>
        <p>“Restricciones de la licencia” significa las restricciones enumeradas en la sección 3, la violación de las mismas constituye un incumplimiento de este Contrato.</p>
        <p></p>
        <p>“PROFECO” significa la Procuraduría Federal del Consumidor.</p>
        <p></p>
        <p>“Sistema” significa el software de comunicaciones electrónicas que le permite comunicarse con HeyCredito para los propósitos de los Servicios.</p>
        <p></p>
        <p>“SMS”, un servicio de mensajes cortos que consiste en un mensaje de texto transmitido desde su teléfono móvil a otro.</p>
        <p></p>
        <p>“Servicios” incluirá cualquier tipo de servicios o productos que HeyCredito pueda ofrecerle en virtud del presente Contrato, incluyendo Créditos y, según usted, de tiempo en tiempo, adquiera dichos servicios.</p>
        <p></p>
        <p>“Solicitud” significa una petición o instrucción, contenida en un Mensaje de Datos, o recibida por HeyCredito de usted a través de la App HeyCredito y el Sistema y sobre la cual HeyCredito está autorizada para actuar.</p>
        <p></p>
        <p>“Tarjeta SIM” significa el módulo de identidad del suscriptor, que cuando se utiliza con el teléfono móvil adecuado le permite tener acceso a la red de telecomunicaciones.</p>
        <p></p>
        <p>“Tecnología” significa los servicios utilizados o soportados por la App.</p>
        <p></p>
        <p>“Términos y Condiciones” se refieren a este Contrato junto con el Aviso de Privacidad y, en caso de celebrarse, las páginas de la Oferta del Crédito y los Contratos de Crédito celebrados entre usted y HeyCredito</p>
        <p></p>
        <p>Sección 2. ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES</p>
        <p></p>
        <p>Se considerará que usted ha firmado este Contrato al hacer clic en la opción “Aceptar” en el sistema de la App HeyCredito pidiéndole que confirme que lo ha leído, entendido y aceptado y que se encuentra obligado por este Contrato. Para todos los efectos haciendo clic en el botón “Aceptar” se considerará una Firma Electrónica. Si no está de acuerdo con este Contrato, por favor dé clic en el botón “Declinar” u opción (similar) en nuestro Sistema. Tenga en cuenta que no podrá acceder a los Servicios y obtener una licencia para la App si hace clic en el botón “Declinar” o en una opción (similar).</p>
        <p></p>
        <p>Al descargar la App HeyCredito y abrir una Cuenta, usted acepta cumplir y estar obligado por los Términos y Condiciones que rigen la prestación de los Servicios y usted afirma que los Términos y Condiciones del presente son sin perjuicio de cualquier otro derecho que podamos tener con respecto a los Servicios y/o Cuenta en la ley o de otra manera.</p>
        <p></p>
        <p>Este Contrato podrá ser modificado o variado de vez en cuando y el uso continuo de los Servicios constituye su acuerdo de estar sujeto a los términos de dichas modificaciones o variaciones.</p>
        <p></p>
        <p>Al utilizar la App o cualquiera de los Servicios, usted acepta que recopilemos y utilicemos la información técnica sobre el Equipo y el software, hardware y periféricos relacionados para los Servicios que están basados en Internet o inalámbricos para mejorar nuestros productos y proporcionarle cualesquier Servicios. Si utiliza estos Servicios, usted acepta las siguientes acciones por parte de nosotros, nuestras afiliadas y licenciatarios: la transmisión, recopilación, conservación, mantenimiento, procesamiento y uso de sus datos para la prestación de los Servicios, así como para mejorar nuestros Servicios y/o su experiencia durante el uso de la App.</p>
        <p></p>
        <p>Asimismo, usted en este acto expresamente acepta que HeyCredito autentifique el acceso a la App, su identidad como usuario de la App, así como su consentimiento, a través de medios electrónicos, ópticos o digitales, pudiendo los mismos ser utilizados como Firma Electrónica, incluyendo para celebrar cualesquiera Contratos de Crédito, así como para que usted acepte que HeyCredito lleve a cabo consultas sobre su historial crediticio ante el Buró de Crédito.</p>
        <p></p>
        <p>Sección 3. ALCANCE DE LA LICENCIA</p>
        <p></p>
        <p>En consideración de que usted acepta obligarse por los términos de este Contrato, le otorgamos una licencia no transferible y no exclusiva para usar la App en su Equipo, sujeto a lo previsto en este Contrato. Nos reservamos todos los demás derechos.</p>
        <p></p>
        <p>A excepción de lo expresamente establecido en el presente Contrato, usted se obliga a:</p>
        <p></p>
        <p>• no alquilar, arrendar, sub-licenciar, prestar, traducir, fusionar, adaptar, variar o modificar la App;</p>
        <p></p>
        <p>• no hacer alteraciones o modificaciones de la totalidad o parte de la App, o permitir que la App o cualquier parte de ella se combine con, o se incorpore en cualesquier otro programas;</p>
        <p></p>
        <p>• no desmontar, descompilar, realizar ingeniería inversa o crear trabajos derivados basados en la totalidad o en parte de la App o intentar hacer tal cosa;</p>
        <p></p>
        <p>• No poner a disposición a ninguna persona la App, en su totalidad o en parte (incluyendo el código fuente y objeto), en ninguna forma sin el consentimiento previo por escrito de HeyCredito;</p>
        <p></p>
        <p>• y cumplir con todas las leyes y reglamentos de control o exportación de tecnología que se apliquen a la tecnología utilizada o soportada por la App o cualquiera de los Servicios.</p>
        <p></p>
        <p>Restricciones de la Licencia: Usted no está autorizado a, y es una violación de este Contrato:</p>
        <p></p>
        <p>• usar la App o cualquier Servicio de cualquier manera ilegal, para cualquier propósito ilegal, o de cualquier manera inconsistente con este Contrato, o actuar de forma fraudulenta o maliciosa, por ejemplo, hackear o insertar algún código malicioso, incluyendo virus, o datos dañinos en la App, cualquier Servicio o cualquier sistema operativo;</p>
        <p></p>
        <p>• infringir nuestros derechos de propiedad intelectual o los de cualquier tercero en relación con su uso de la App o cualquier Servicio, incluyendo la presentación de cualquier material (en la medida en que tal uso no esté autorizado por este Contrato);</p>
        <p></p>
        <p>• transmitir cualquier material que sea difamatorio, ofensivo o de alguna otra manera objetable en relación con su uso de la App o de cualquier Servicio;</p>
        <p></p>
        <p>• utilizar la App o cualquier Servicio de una manera que pueda dañar, deshabilitar, sobrecargar, deteriorar o comprometer nuestros sistemas o seguridad o interferir con otros usuarios; y/o</p>
        <p></p>
        <p>• recopilar o recolectar cualquier información o datos de cualquier Servicio o de nuestros sistemas o intentar descifrar cualquier transmisión hacia o desde los servidores que ejecutan cualquier Servicio.</p>
        <p></p>
        <p>Sección 4. DERECHOS DE PROPIEDAD INTELECTUAL</p>
        <p></p>
        <p>Usted reconoce que todos los derechos de propiedad intelectual de la App y la Tecnología en cualquier parte del mundo pertenecen a HeyCredito, que los derechos de la App están licenciados (no vendidos) a usted y que usted no tiene derechos sobre la App o la Tecnología distintos del derecho a utilizar cada uno de ellos de conformidad con los términos de este Contrato. Usted reconoce que no tiene derecho a tener acceso al código fuente de la App.</p>
        <p></p>
        <p>Sección 5. USO DE LOS SERVICIOS</p>
        <p></p>
        <p>Nuestros Servicios sólo pueden ser utilizados por personas mayores de 18 años de edad. HeyCredito se reserva el derecho de verificar la autenticidad y el estado de su identidad con el proveedor de telecomunicaciones correspondiente, cualquier banco u otro tercero.</p>
        <p></p>
        <p>Nos reservamos el derecho de rechazar su Solicitud para un Crédito o revocar la misma en cualquier momento a nuestra única y absoluta discreción y sin asignar ninguna razón o dar aviso a la misma (excepto cuando sea requerido por la ley aplicable).</p>
        <p></p>
        <p>Nos reservamos el derecho (a nuestra única y absoluta discreción) para emitir, negarse a emitir un Crédito y/o variar los términos ofrecidos respecto de cualquier Crédito en función de nuestra evaluación del perfil de crédito de cada deudor individual de tiempo en tiempo. Los términos del Crédito y la Comisión por Servicio pagaderos en relación con cada Solicitud para un Crédito serán desplegados en la App.</p>
        <p></p>
        <p>Sección 6. INFORMACIÓN PERSONAL</p>
        <p></p>
        <p>Usted en este acto acuerda y nos autoriza a verificar la información incluyendo la Información Personal y la Información Relevante, con otros terceros que consideremos necesarios para evaluar su Solicitud para un Crédito.</p>
        <p></p>
        <p>En este acto usted consiente que nosotros utilicemos la Información Personal y la Información Relevante en la medida necesaria de conformidad con el Aviso de Privacidad.</p>
        <p></p>
        <p>Nos reservamos el derecho a solicitar más información a usted en relación con su Solicitud para un Crédito en cualquier momento. La omisión de dicha información dentro del tiempo requerido por nosotros puede resultar en la declinación de aceptar su Solicitud para un Crédito.</p>
        <p></p>
        <p>HeyCredito se reserva el derecho de suministrar información de crédito del consumidor a los Burós de Crédito, y en este sentido: usted acepta que podamos transmitir la información en la Cuenta a los Burós de Crédito, incluyendo, información sobre el incumplimiento a cualquier Contrato de Crédito.</p>
        <p></p>
        <p>Sección 7. Códigos de Acceso y Números Confidenciales.</p>
        <p></p>
        <p>En todo momento, usted será el único responsable de mantener seguros sus números de cuenta, código de acceso, contraseñas o claves de acceso personales y el número confidencial con los que tiene acceso a la App, y en consecuencia a los servicios prestados por HeyCredito, así como sitios web de HeyCredito o de terceros. Usted acepta que dichos números de cuenta, códigos de acceso, contraseñas personales o códigos de acceso y números confidenciales se consideran como Firma Electrónica de conformidad con el artículo 89 del Código de Comercio. En virtud de lo anterior, HeyCredito será considerado como un Destinatario en el momento de recibir cualquier Mensaje de Datos o de instrucciones del usuario a través de la App al acceder con sus claves de acceso, números NIP, contraseñas o claves de acceso personales y/o números confidenciales. Como consecuencia de lo anterior:</p>
        <p></p>
        <p>• HeyCredito seguirá las instrucciones recibidas a través de la App o de cualquier Mensaje de Datos, incluso cuando dicha instrucción haya sido hecha por un tercero distinto a usted mediante el uso de los códigos de acceso mencionados anteriormente, códigos de acceso personales o contraseñas y/o números confidenciales.</p>
        <p></p>
        <p>• Usted acuerda cumplir debidamente con cualquier obligación en la que usted haya incurrido en relación con la ejecución por HeyCredito de las instrucciones recibidas a través de la App o de Mensajes de Datos, a través del uso de dichos códigos de acceso, contraseñas o claves de acceso personales y/o números confidenciales. En virtud de lo anterior, usted deberá cambiar periódicamente los códigos de acceso, números NIP, contraseñas o claves de acceso personales y/o números confidenciales con el fin de minimizar el riesgo de que terceros tengan conocimiento de ellos.</p>
        <p></p>
        <p>Sección 8. SOLICITUDES FORMULADAS POR EL DEUDOR</p>
        <p></p>
        <p>Por el presente, usted nos autoriza irrevocablemente a actuar sobre todas las Solicitudes recibidas de usted a través del Sistema y para considerarlo responsable con respecto a las mismas. Podemos, sin embargo, negarnos a realizar cualquier Solicitud a nuestra entera y absoluta discreción. Sujeto a nuestra discreción, nos reservamos el derecho de rechazar cualquier solicitud en relación con un Crédito de usted, incluso si previamente se le ha emitido con un Crédito por nosotros.</p>
        <p></p>
        <p>Tendremos el derecho de aceptar y actuar sobre cualquier Solicitud, incluso si esa Solicitud es de otra manera por cualquier motivo incompleta o ambigua si, en nuestra discreción absoluta, creemos que podemos corregir la información incompleta o ambigua en la Solicitud sin ninguna acción adicional que sea necesaria por parte de usted.</p>
        <p></p>
        <p>Se considerará que ha actuado adecuadamente y ha cumplido plenamente con todas las obligaciones que le corresponden, a pesar de que la Solicitud puede haber sido iniciada, enviada o comunicada de otra manera en error o de manera fraudulenta y usted estará obligado por cualesquier Solicitudes en las que podamos actuar, si hemos actuado de buena fe, y se actuó en la creencia de que tales instrucciones fueron enviados por usted.</p>
        <p></p>
        <p>Podemos, a nuestra entera discreción, rehusarnos a actuar de conformidad con la totalidad o parte de su Solicitud, a la espera de una nueva investigación o confirmación adicional (escrita o no) de su parte.</p>
        <p></p>
        <p>Usted reconoce que, en la medida permitida por la ley, nosotros no seremos responsables de cualquier depósito, transferencia, envío, divulgación, o cualquier incidente no autorizados en su Cuenta debido al hecho del conocimiento y/o uso o manipulación de su contraseña de la Cuenta, número de NIP, documento(s) de identificación o cualquier medio que sea o no, causados por su negligencia.</p>
        <p></p>
        <p>Usted nos autoriza para efectuar dichas órdenes con respecto a cualquier Crédito y/o Cuenta según requerido por orden judicial o autoridad competente o agencia bajo las leyes aplicables. En caso de cualquier conflicto entre los términos de cualquier Solicitud recibida por nosotros de usted y este Contrato, este Contrato prevalecerá.</p>
        <p></p>
        <p>Sección 9. Servicios y Soporte al Cliente.</p>
        <p></p>
        <p>Si desea ponerse en contacto con nosotros por escrito o tiene alguna pregunta con respecto al saldo de su Cuenta, actividad, transacciones y aclaraciones, entre otros, o si alguna de las condiciones de este Contrato requiere que nos notifique, puede enviarnos esto por correo electrónico a ********** o a la dirección de correo electrónico que le será comunicada de tiempo en tiempo. Le confirmaremos la recepción de esta información poniéndonos en contacto con usted por correo electrónico. Cualquier pregunta o consultas con respecto al saldo de su Cuenta, la actividad, transacciones y aclaraciones serán respondidas por nosotros después de recibir sus preguntas o consultas por correo electrónico u otro método de comunicación.</p>
        <p></p>
        <p>Si tenemos que ponernos en contacto con usted o darle aviso por escrito, lo haremos por correo electrónico, por SMS y/o por WhatsApp al número de teléfono móvil o dirección de correo electrónico que nos proporcione en su solicitud de la App. Por medio de la presente, usted acuerda recibir aviso por escrito a través de dichos métodos.</p>
        <p></p>
        <p>Si desea comunicarse con nosotros por teléfono, puede ponerse en contacto con nosotros al siguiente número de teléfono:</p>
        <p></p>
        <p>Si desea ponerse en contacto con Profeco:</p>
        <p></p>
        <p>Tel: (52) 4423676854</p>
        <p></p>
        <p></p>
        <p>Dirección: Av. José Vasconcelos 208,</p>
        <p></p>
        <p>Col. Condesa, Del. Cuauhtémoc, Ciudad de México. CP 06140.</p>
        <p></p>
        <p>Sección 10. RESPONSABILIDADES DEL USUARIO</p>
        <p></p>
        <p>Usted deberá, a su propio costo, proporcionar y mantener en estado de funcionamiento seguro y eficiente de su Equipo, necesario para la finalidad de acceder al Sistema y de los Servicios.</p>
        <p></p>
        <p>Usted será responsable de asegurar el correcto funcionamiento de su Equipo. Nosotros no seremos responsables de los errores o fallos causados por un mal funcionamiento de su Equipo y tampoco seremos responsables por cualquier virus informático o problemas relacionados que pueden estar asociados con el uso del Sistema y/o los Servicios o el Equipo. Usted será responsable de los cargos debido a cualquier proveedor de servicios que le proporcione conexión a la red y nosotros no seremos responsables por pérdidas o retrasos causados por cualquier proveedor de servicios.</p>
        <p></p>
        <p>Usted deberá seguir todas las instrucciones, procedimientos y términos contenidos en este Contrato y cualquier documento proporcionado por nosotros con respecto al uso del Sistema y los Servicios.</p>
        <p></p>
        <p>Usted por el presente acepta y reconoce que usted será el único responsable de la custodia y uso apropiado de su Equipo y de mantener sus Credenciales secretas y seguras. Usted se asegurará de que sus Credenciales no se conozcan o entren en posesión de ninguna persona no autorizada. No nos hacemos responsables de cualquier divulgación de sus Credenciales a terceros, y usted acepta indemnizar y sacarnos en paz y a salvo de cualquier pérdida que resulte de cualquier divulgación de sus Credenciales.</p>
        <p></p>
        <p>Usted tomará todas las precauciones razonables para detectar cualquier uso no autorizado del Sistema y de los Servicios. Para ello, deberá asegurarse de que todas las comunicaciones de nosotros sean examinadas y verificadas por usted o en su nombre tan pronto como sea posible después de su recepción por parte de usted de tal manera que se detectará cualquier uso y acceso no autorizados al Sistema. Usted deberá informarnos inmediatamente en caso de que:</p>
        <p></p>
        <p>• Usted tenga razones para creer que sus Credenciales son o pueden ser conocidas por cualquier persona no autorizada para saber lo mismo y/o han sido comprometidas; y/o</p>
        <p></p>
        <p>• Usted tenga razones para creer que el uso no autorizado de los Servicios ha ocurrido o puede haber ocurrido y que una transacción puede haber sido fraudulentamente ingresada o comprometida.</p>
        <p></p>
        <p>Usted en todo momento deberá seguir los procedimientos de seguridad notificados por nosotros a usted de vez en cuando o cualquier otro procedimiento que pueda ser aplicable a los Servicios de vez en cuando. Usted reconoce que cualquier incumplimiento de su parte de seguir los procedimientos de seguridad recomendados puede resultar en un incumplimiento de la confidencialidad de su cuenta. En particular, se asegurará de que los Servicios no sean utilizados o las Solicitudes no se emitan, o las funciones pertinentes no sean llevadas a cabo por otra persona que no sea una persona autorizada para hacerlo. Usted no podrá operar en cualquier momento o utilizar los Servicios de ninguna manera que pueda razonablemente ser perjudicial para nosotros.</p>
        <p></p>
        <p>Sección 11. VARIACIÓN Y TERMINACIÓN</p>
        <p></p>
        <p>Podemos, en cualquier momento, previo aviso a usted, terminar o modificar nuestra relación de negocios con usted y cerrar su Cuenta.</p>
        <p></p>
        <p>Sin perjuicio de nuestros derechos conforme a esta cláusula, podemos, a nuestra discreción, suspender o cerrar su Cuenta:</p>
        <p></p>
        <p>● Si se utiliza la Cuenta para fines no autorizados, o cuando nosotros detectemos o razonablemente sospechemos de cualquier abuso, fraude o intento de fraude relacionado con el uso de los Servicios;</p>
        <p></p>
        <p>● Si nos vemos obligados o solicitados a cumplir con una orden o instrucción o de una recomendación de una autoridad gubernamental, regulatoria u otra autoridad competente;</p>
        <p></p>
        <p>● Si tenemos razones para sospechar o creer que usted está en incumplimiento de este Contrato o el Contrato de Crédito, que usted no puede subsanar (si es subsanable) dentro de los 5 Días Hábiles después de la entrega de la notificación por correo electrónico, SMS u otros medios electrónicos que le requieran hacerlo;</p>
        <p></p>
        <p>● Cuando tal suspensión o variación sea necesaria como consecuencia de problemas técnicos o por razones de seguridad; para facilitar, actualizar o mejorar los contenidos o los Servicios de vez en cuando;</p>
        <p></p>
        <p>● Si su Cuenta se vuelve inactiva o latente;</p>
        <p></p>
        <p>● Si HeyCredito decide suspender o cesar la prestación de los Servicios por razones comerciales o por cualquier otra razón que puede determinar a su entera discreción; o</p>
        <p></p>
        <p>● Si usted no cumple con cualquiera de los Restricciones de la Licencia.</p>
        <p></p>
        <p>En caso de que Usted pague un monto superior al monto que deba pagar y lo anterior tenga como resultado un saldo a su favor en la Cuenta respecto del saldo insoluto de un Crédito más la Comisión por Servicio o cualquier otro cargo o comisión aplicable, a la fecha final de pago aplicable a su Crédito, Usted expresamente consiente que HeyCredito retenga el monto excedente hasta el momento en que Usted obtenga un Crédito subsecuente con HeyCredito. Una vez que Usted celebre un Crédito subsecuente con HeyCredito, dicho monto excedente será aplicado al pago total de dicho Crédito subsecuente. No obstante lo anterior, (i) en cualquier momento previo a obtener un Crédito subsecuente con HeyCredito, Usted podrá solicitar a HeyCredito a través de una Solicitud que le sea devuelto el monto excedente en su Cuenta, y (ii) una vez que HeyCredito reciba dicha Solicitud, HeyCredito devolverá a Usted dicho monto excedente menos cualesquiera comisiones aplicables.</p>
        <p></p>
        <p>La terminación no afectará a los derechos y obligaciones devengados de cualquiera de las partes.</p>
        <p></p>
        <p>Si nosotros recibimos el aviso de su muerte, nosotros no estaremos obligados a permitir cualquier operación o retiro de su Cuenta por cualquier persona excepto mediante la entrega de un aviso por escrito a HeyCredito. Usted puede dar por terminado este Contrato en cualquier momento a condición que no exista ninguna obligación de pago pendiente relacionada con un Crédito, intereses o tarifa que derive de dicho Crédito pendiente o cualquier otra obligación legal que usted deba a HeyCredito.</p>
        <p></p>
        <p>Sección 12. LÍMITACIÓN DE RESPONSABILIDAD</p>
        <p></p>
        <p>No seremos responsables de ninguna pérdida sufrida por usted en caso de que los Servicios sean interferidos o no estén disponibles por el fracaso de cualquiera de sus Equipos, o cualquier otra circunstancia que no esté bajo el control de HeyCredito incluyendo, sin limitación, Fuerza Mayor o error, interrupción, retraso o falta de disponibilidad del Sistema, falla en el equipo por acción terrorista o cualquier acción del enemigo, pérdida de potencia, condiciones climáticas adversas o condiciones atmosféricas y fallas de cualquier sistema de telecomunicaciones público o privado.</p>
        <p></p>
        <p>Usted reconoce que la App no se ha desarrollado para satisfacer sus necesidades individuales y que, por lo tanto, es su responsabilidad asegurarse de que las instalaciones y funciones de la App, según se describen, cumplan con sus requisitos.</p>
        <p></p>
        <p>Sólo suministramos la App para uso doméstico y privado. Usted se compromete a no utilizar la App y documentos para cualesquier fines comerciales, de negocios o reventa comercial, y no tenemos ninguna responsabilidad hacia usted por cualquier pérdida, pérdida de negocio, interrupción de negocio o pérdida de oportunidad de negocio.</p>
        <p></p>
        <p>No seremos responsables de ninguna pérdida o daño sufrido por usted como resultado o en relación con:</p>
        <p></p>
        <p>● un defecto o falla en de la App o cualquier servicio que resulte de que usted alteró o modificó la App;</p>
        <p></p>
        <p>● un defecto o fallo en la App que resulte de haber utilizado la App en incumplimiento de los términos de este Contrato;</p>
        <p></p>
        <p>● su incumplimiento a cualquiera de las Restricciones de la Licencia;</p>
        <p></p>
        <p>● falla, mal funcionamiento, interrupción o falta de disponibilidad del Sistema, su equipo o la red de telecomunicaciones;</p>
        <p></p>
        <p>● el dinero en su Cuenta esté sujeto a proceso legal u otro Gravamen que restrinja los pagos o transferencias de los mismos;</p>
        <p></p>
        <p>● su falta de dar instrucciones apropiadas o completas para pagos o transferencias relacionadas con su Cuenta;</p>
        <p></p>
        <p>● su uso fraudulento o ilegal de los Servicios, el Sistema y/o su Equipo; o</p>
        <p></p>
        <p>● su incumplimiento de este Contrato y cualquier documento o información proporcionada por nosotros con respecto al uso del Sistema y de los Servicios.</p>
        <p></p>
        <p>Todas las garantías y obligaciones implícitas por la ley quedan en este acto excluidas en la mayor medida permitida por la ley.</p>
        <p></p>
        <p>Sección 13. INDEMNIZACIÓN</p>
        <p></p>
        <p>Como consecuencia de que cumplamos con sus instrucciones o Solicitudes en relación con su Cuenta, usted se compromete a indemnizarnos y sacarnos en paz y a salvo de cualquier pérdida, cargo, daño, gasto, honorario o reclamación que suframos, incurramos o sostengamos por ello y usted nos absuelve de toda responsabilidad por la pérdida o daño que usted pueda sostener de nosotros actuando sobre sus instrucciones o solicitudes o de conformidad con este Contrato.</p>
        <p></p>
        <p>La indemnización también cubrirá lo siguiente:</p>
        <p></p>
        <p>• Todas las demandas, reclamos, acciones, pérdidas y daños de cualquier naturaleza que puedan ser iniciados contra nosotros o que podamos sufrir o incurrir por su actuación o no actuando sobre cualquier Solicitud o que surjan del mal funcionamiento o falla o indisponibilidad de cualquier hardware, software, o equipo; la pérdida o destrucción de cualesquier datos, fallas de potencia, corrupción de medios de almacenamiento, fenómenos naturales, rebeliones, actos de vandalismo, sabotaje, terrorismo y cualquier otro evento más allá de nuestro control; interrupción o distorsión de lazos de comunicación o que surjan de la confianza en cualquier persona o en cualquier información incorrecta, ilegible, incompleta o incierta o datos contenidos en cualquier Solicitud recibida por nosotros.</p>
        <p></p>
        <p>• Cualquier pérdida o daño que pueda derivarse de su uso, mal uso, abuso o posesión de cualquier software de terceros, incluyendo, sin limitación, cualquier sistema operativo, software de navegación o cualquier otro paquete de software o programas.</p>
        <p></p>
        <p>• Cualquier acceso no autorizado a su Cuenta o cualquier violación de seguridad o cualquier destrucción al acceso de sus datos o, cualquier destrucción o robo o daño a cualquiera de su Equipo.</p>
        <p></p>
        <p>• Cualquier pérdida o daño ocasionado por el incumplimiento de usted en adherirse a este Contrato y/o mediante el suministro de información incorrecta o pérdida o daño ocasionado por la falla o falta de disponibilidad de las instalaciones de terceros o sistemas o la incapacidad de un tercero para procesar una transacción o cualquier pérdida en la que podamos incurrir nosotros como consecuencia de cualquier incumplimiento de este Contrato.</p>
        <p></p>
        <p>• Cualesquier daños y costos pagaderos a nosotros con respecto a cualquier reclamación contra nosotros para la compensación por la pérdida en que la circunstancia particular está bajo su control.</p>
        <p></p>
        <p>Sección 14. GENERAL</p>
        <p></p>
        <p>Remedios Acumulativos. Ninguna falla por parte de cualquiera de las partes que ejercen o la demora de su parte en el ejercicio de cualquier derecho, facultad o recurso previsto en el presente Contrato o por ley, deben operar como una renuncia a los mismos, ni el ejercicio individual o parcial de cualquiera de dichos derechos, facultad o recurso impedirá el ejercicio posterior u otro ejercicio de este, o cualquier otro, derecho, poder o recurso.</p>
        <p></p>
        <p>No renuncia. La falta por parte de nosotros para ejercer y ninguna demora en ejercer cualquier derecho o recurso con respecto a cualquier disposición de este Contrato, constituirá una renuncia a tal derecho o recurso.</p>
        <p></p>
        <p>Efecto de la Invalidez. Si cualquier disposición o parte de una disposición de este Contrato es, declarada por cualquier tribunal de jurisdicción competente como inválido o inejecutable, dicha invalidez o inejecutabilidad no afectará a las otras disposiciones o partes de tales disposiciones del presente Contrato, todas las cuales permanecerán en pleno vigor y efecto.</p>
        <p></p>
        <p>Cesión. Podemos, en cualquier momento, sin previo aviso o consentimiento de usted, ceder la totalidad o parte de nuestros derechos y obligaciones bajo este Contrato. Usted no podrá ceder ninguno de sus derechos u obligaciones del presente Contrato.</p>
        <p></p>
        <p>Sección 15. TOTALIDAD DEL CONTRATO</p>
        <p></p>
        <p>Los Términos y Condiciones constituyen el Contrato completo entre usted y nosotros respecto al uso de la App HeyCredito y reemplazan y extinguen todos los acuerdos, promesas, garantías, declaraciones y entendimientos previos entre nosotros, ya sean escritos u orales, en relación con su objeto. Lo anterior sin perjuicio de cualquier Contrato de Crédito celebrado con Usted.</p>
        <p></p>
        <p>Usted reconoce que al celebrar este Contrato no se basa en ninguna declaración, aseguramiento o garantía que no esté establecido en estos Términos y Condiciones.</p>
        <p></p>
        <p>Sección 16. RESOLUCIÓN DE CONFLICTOS</p>
        <p></p>
        <p>Las partes utilizarán sus esfuerzos de buena fe para resolver cualquier disputa, controversia o reclamación de cualquier naturaleza que surjan de o en relación con este Contrato.</p>
        <p></p>
        <p>Sección 17. LEY APLICABLE</p>
        <p></p>
        <p>Este Contrato se regirá e interpretará de acuerdo con las leyes de los Estados Unidos Mexicanos. Las partes acuerdan someterse exclusivamente a la jurisdicción de los tribunales competentes en la Ciudad de México y expresamente renuncian a cualquier otra jurisdicción a la que pudieran tener derecho por virtud de sus domicilios presentes o futuros o de cualquier otra forma.</p>
        <p></p>
        <p>Sección 18. AVISO DE PRIVACIDAD</p>
        <p></p>
        <p>Sólo utilizamos su información personal de conformidad con nuestro Aviso de Privacidad. Por favor tome el tiempo para leer nuestro Aviso de Privacidad, ya que incluye términos importantes que se aplican a usted.</p>
        <p></p>
        <p>Tras la descarga de la App y haciendo clic en la opción “Aceptar” con respecto a estos Términos y Condiciones, se considerará que ha leído, comprendido y aceptado el Aviso de Privacidad de HeyCredito, cuya copia está disponible en la App.</p>
        <p></p>
      </div>
    );
  }
}
export default CreditAgreement;
